body .cm-s-one-dark.CodeMirror {
    background: #282c34;
    color: #abb2bf;
}

body .cm-s-one-dark div.CodeMirror-selected {
    background: #3e4451;
}

body .cm-s-one-dark .CodeMirror-line::selection,
body .cm-s-one-dark .CodeMirror-line > span::selection,
body .cm-s-one-dark .CodeMirror-line > span > span::selection {
    background: rgba(73, 72, 62, 0.99);
}

body .cm-s-one-dark .CodeMirror-line::-moz-selection,
body .cm-s-one-dark .CodeMirror-line > span::-moz-selection,
body .cm-s-one-dark .CodeMirror-line > span > span::-moz-selection {
    background: rgba(73, 72, 62, 0.99);
}

body .cm-s-one-dark .CodeMirror-gutters {
    background: transparent;
    border-right: none;
}

body .cm-s-one-dark .CodeMirror-guttermarker {
    color: white;
}

body .cm-s-one-dark .CodeMirror-guttermarker-subtle {
    color: #d0d0d0;
}

body .cm-s-one-dark .CodeMirror-linenumber {
    color: #4b5363;
}

body .cm-s-one-dark .CodeMirror-cursor {
    border-left: 1px solid #528bff;
}

body .cm-s-one-dark span.cm-comment {
    color: #667689;
}

body .cm-s-one-dark span.cm-atom {
    color: #64b6c3;
}

body .cm-s-one-dark span.cm-number {
    color: #d19a66;
}

body .cm-s-one-dark span.cm-property,
body .cm-s-one-dark span.cm-attribute {
    color: #abb2bf;
}

body .cm-s-one-dark span.cm-keyword {
    color: #d86c74;
}

body .cm-s-one-dark span.cm-string {
    color: #98c379;
}

body .cm-s-one-dark span.cm-variable {
    color: #abb2bf;
}

body .cm-s-one-dark span.cm-variable-2 {
    color: #c678dd;
}

body .cm-s-one-dark span.cm-variable-3 {
    color: #64b6c3;
}

body .cm-s-one-dark span.cm-def {
    color: #6caff2;
}

body .cm-s-one-dark span.cm-bracket {
    color: #abb2bf;
}

body .cm-s-one-dark span.cm-tag {
    color: #d86c74;
}

body .cm-s-one-dark span.cm-header {
    color: #abb2bf;
}

body .cm-s-one-dark span.cm-link {
    color: #98c379;
}

body .cm-s-one-dark span.cm-error {
    background: #f92672;
    color: red;
}

body .cm-s-one-dark .CodeMirror-activeline-background {
    background: #2c323b;
}

body .cm-s-one-dark .CodeMirror-matchingbracket {
    text-decoration: underline;
    color: #abb2bf !important;
}

body .cm-s-one-dark .CodeMirror-cursor {
    background: white !important;
}
